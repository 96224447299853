<template>
  <div style="width: max-content">
    <a-tree
      :block-node="true"
      :draggable="canOperate"
      :replace-fields="replaceFields"
      :tree-data="treeData"
      @select="selectedKeys => $emit('select', selectedKeys[0])"
      @drop="drop"
      :expanded-keys.sync="expandedRowKeys"
    >
      <template slot="title" slot-scope="node">
        <a-dropdown :trigger="['contextmenu']" style="width: 100%">
          <a-space>
            <a-icon :type="node.children && node.children.length ?  'folder' : 'file'"></a-icon>
            <span>{{ node.name }}</span>
          </a-space>

          <template slot="overlay" v-if="canOperate">
            <a-menu>
              <a-menu-item>
                <create-button
                  type="link"
                  size="small"
                  v-if="apiPermission.create"
                  @click="$refs.createModal.show(node)"
                  v-permission="{action: apiPermission.create, effect: 'disabled'}"
                >
                </create-button>
                <create-button
                  type="link"
                   size="small"
                  v-else
                  @click="$refs.createModal.show(node)"
                >
                </create-button>
              </a-menu-item>
              <a-menu-item>
                <edit-button
                  v-if="apiPermission.edit"
                  v-permission="{action: apiPermission.edit, effect: 'disabled'}"
                  @click="$refs.updateModal.show(node)"
                ></edit-button>
                <edit-button
                  v-else
                  @click="$refs.updateModal.show(node)"
                ></edit-button>
              </a-menu-item>
              <a-menu-item v-if="node.name !== '全部'">
                <group-tree-delete-button
                  @click="deleteNode(node)"
                  v-if="apiPermission.delete"
                  v-permission="{action: apiPermission.delete, effect: 'disabled'}"
                  style="color: #f5222d"
                >
                </group-tree-delete-button>
                <group-tree-delete-button
                  v-else
                  @click="deleteNode(node)"
                  style="color: #f5222d"
                >
                </group-tree-delete-button>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </template>
    </a-tree>

    <create-modal
      ref="createModal"
      :createFunc="createFunc"
      @ok="fetch()"
    ></create-modal>
    <update-modal
      ref="updateModal"
      :update-func="updateFunc"
      @ok="fetch()"
    ></update-modal>
  </div>
</template>

<script>
import EditButton from '@/components/button/EditButton'
import GroupTreeDeleteButton from '@/components/button/GroupTreeDeleteButton'
import CreateButton from '@/components/button/CreateButton'
import CreateModal from './modules/CreateModal'
import UpdateModal from './modules/UpdateModal'

export default {
  name: 'GroupTree',
  components: {
    CreateModal,
    EditButton,
    GroupTreeDeleteButton,
    CreateButton,
    UpdateModal
  },
  props: {
    createFunc: {
      type: Function,
      required: true
    },
    deleteFunc: {
      type: Function,
      required: true
    },
    getListFunc: {
      type: Function,
      required: true
    },
    updateFunc: {
      type: Function,
      required: true
    },
    canOperate: {
      type: Boolean,
      default: true
    },
    apiPermission: {
      type: Object
    }
  },
  data () {
    return {
      replaceFields: {
        children: 'children',
        title: 'name',
        key: 'id'
      },
      treeData: [],
      expandedRowKeys: []
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      this.getListFunc().then(res => {
        this.treeData = res.data
        if (this.treeData.length) this.expandedRowKeys = [this.treeData[0].id]
      })
    },
    deleteNode (node) {
      const that = this
      this.$confirm({
        title: '是否确定删除？',
        okType: 'danger',
        onOk () {
          that.deleteFunc(node.id).then(res => {
            that.$message.success(res.message)
            that.fetch()
          })
        }
      })
    },
    drop ({ dragNode, dropToGap, node }) {
      if (!dropToGap) {
        this.updateFunc(dragNode.eventKey, { parent_id: node.eventKey }).then(res => {
          this.$message.success(res.message)
          this.fetch()
        })
      }
    }
  }
}
</script>
