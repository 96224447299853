<template>
  <a-button
    icon="delete"
    type="link"
    size="small"
    @click="e => $emit('click', e)"
  >
    删除
  </a-button>
</template>

<script>
export default {
  name: 'GroupTreeDeleteButton'
}
</script>
