var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"confirm-loading":_vm.loading,"title":"群组编辑","visible":_vm.visible,"width":720},on:{"cancel":_vm.reset,"ok":_vm.ok}},[_c('a-form-model',{ref:"form",attrs:{"label-col":{
      span: 4,
      style: {
        textAlign: 'left'
      }
    },"model":_vm.form,"rules":_vm.rules}},[_c('a-form-model-item',{attrs:{"label":"群组名称","wrapper-col":{
        span: 20
      },"prop":"name"}},[_c('a-input',{attrs:{"max-length":128,"placeholder":"请输入群组名称"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }