<template>
  <a-modal
    :confirm-loading="loading"
    title="群组编辑"
    :visible="visible"
    :width="720"
    @cancel="reset"
    @ok="ok"
  >
    <a-form-model
      ref="form"
      :label-col="{
        span: 4,
        style: {
          textAlign: 'left'
        }
      }"
      :model="form"
      :rules="rules"
    >
      <a-form-model-item
        label="群组名称"
        :wrapper-col="{
          span: 20
        }"
        prop="name"
      >
        <a-input
          v-model="form.name"
          :max-length="128"
          placeholder="请输入群组名称"
        ></a-input>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
export default {
  name: 'UpdateModal',
  props: {
    updateFunc: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      visible: false,
      form: {
        name: ''
      },
      rules: {
        name: [
          {
            message: '请输入群组名称',
            required: true,
            trigger: 'blur'
          }
        ]
      },
      node: {}
    }
  },
  methods: {
    show (node) {
      this.node = node
      this.form.name = node.name
      this.visible = true
    },
    reset () {
      this.form = {
        name: ''
      }
      this.$refs.form.resetFields()
      this.visible = false
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          this.updateFunc(this.node.id, this.form)
            .then(res => {
              this.$message.success(res.message)
              this.$emit('ok', this.node.id)
              this.reset()
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    }
  }
}
</script>
